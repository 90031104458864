<template>
  <div>
    <div style="margin: 10px 0">
      <el-input style="width: 200px" placeholder="请输入商品名称" clearable v-model="vo.itemCommodityName"></el-input>
      <el-input style="width: 200px" placeholder="请输入商品编号" clearable class="ml-5"
                v-model="vo.itemCommodityId"></el-input>
      <el-input style="width: 200px" placeholder="请输入发货人" clearable class="ml-5" v-model="vo.originName"></el-input>
      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
    </div>

    <div style="margin: 10px 0">
      <!--      <el-button type="primary" @click="handleAdd">新增 <i class="el-icon-circle-plus-outline"></i></el-button>-->
      <el-button type="primary" @click="handleShowZip()">压缩包批量导入 <i class="el-icon-circle-plus-outline"></i>
      </el-button>
      <el-popconfirm
          class="ml-5"
          confirm-button-text='确定'
          cancel-button-text='我再想想'
          icon="el-icon-info"
          icon-color="red"
          title="您确定批量删除这些数据吗？"
          @confirm="delBatch"
      >
        <el-button type="danger" slot="reference">批量删除 <i class="el-icon-remove-outline"></i></el-button>
      </el-popconfirm>
      <el-upload :action="`${path}/itemCommodityInfo/excel/import`" :show-file-list="false" accept="xlsx"
                 :on-success="handleExcelImportSuccess" style="display: inline-block">
        <el-button type="primary" class="ml-5">导入 <i class="el-icon-bottom"></i></el-button>
      </el-upload>
      <el-button type="primary" @click="exp" class="ml-5">导出 <i class="el-icon-top"></i></el-button>
    </div>

    <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"
              @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" fixed="left"></el-table-column>
      <el-table-column prop="id" label="ID" width="80" sortable fixed="left"></el-table-column>
      <el-table-column prop="commodityId" label="商品编号" width="120" fixed="left"></el-table-column>
      <!--      <el-table-column prop="avatarImag" label="商品图片" fixed="left"></el-table-column>-->
      <el-table-column prop="originPath" label="商品图片" fixed="left">

        <template slot-scope="scope">
          <img :src="`${piPath}${scope.row.originPath}`" alt="商品图片" style="width: 100px; height: auto;">
        </template>
      </el-table-column>
      <el-table-column prop="itemCommodityName" label="商品名字" width="120" fixed="left"></el-table-column>
      <!--      <el-table-column prop="categoryId" label="商品类别"></el-table-column>-->
      <el-table-column prop="content" label="商品类别" width="200"></el-table-column>
      <el-table-column prop="originPrice" label="商品原价"></el-table-column>
      <el-table-column prop="price" label="商品现价"></el-table-column>
      <el-table-column prop="details" label="商品介绍" width="200">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" :content="scope.row.details" placement="top">
            <div>{{ scope.row.details }}</div> <!-- 如果需要在表格中显示内容 -->
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="inventory" label="库存"></el-table-column>
      <el-table-column prop="originPhone" label="发货人的电话" width="200"></el-table-column>
      <el-table-column prop="originName" label="发货人的名字" width="120"></el-table-column>
      <el-table-column prop="originAddress" label="发货地" width="200"></el-table-column>
      <el-table-column prop="acceptAddressList" label="支持发货的地区集合" width="200"></el-table-column>
      <el-table-column prop="freeShippingNum" label="免运费的起始订购数量" width="200"></el-table-column>
      <!--      <el-table-column prop="itemId" label="网店id" width="120"></el-table-column>-->
      <el-table-column prop="degreeLoss" label="商品的折损" width="100"></el-table-column>
      <el-table-column prop="status" label="商品状态" :formatter="formatStatus"></el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="200"></el-table-column>
      <el-table-column prop="updateTime" label="修改时间" width="200"></el-table-column>
      <el-table-column label="操作" width="280" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button type="success" @click="handleEdit(scope.row)">编辑 <i class="el-icon-edit"></i></el-button>
          <el-popconfirm
              class="ml-5"
              confirm-button-text='确定'
              cancel-button-text='我再想想'
              icon="el-icon-info"
              icon-color="red"
              title="您确定删除吗？"
              @confirm="del(scope.row.id)"
          >
            <el-button type="danger" slot="reference">删除 <i class="el-icon-remove-outline"></i></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div style="padding: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[20, 50, 100, 200,300,400,500]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <el-dialog title="压缩包导入" :visible.sync="showBatchZip" width="60%">
      <el-form label-width="200px" size="small">
        <el-form-item label="压缩包">
          <el-upload
              class="upload-demo"
              action=""
              :auto-upload="false"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              :before-upload="beforeUpload"
              :on-change="handleChange"
              multiple
              :limit="1"
              :on-exceed="handleExceed"
              accept=".zip"
              :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传一个zip文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="编号开头">
          <el-input v-model="batchDTO.mark" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="发货人电话">
          <el-input v-model="batchDTO.originPhone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="发货人名字">
          <el-input v-model="batchDTO.originName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="发货人地址">
          <el-input v-model="batchDTO.originAddress" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="商品状态">
          <el-select v-model="batchDTO.status" placeholder="请选择">
            <el-option
                v-for="item in status"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品一级分类">
          <el-select v-model="categoryPid" filterable placeholder="请选择">
            <el-option
                v-for="item in firstSort"
                :key="item.categoryId"
                :label="item.content"
                :value="item.categoryId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品二级分类">
          <el-select v-model="batchDTO.categoryId" filterable placeholder="请选择">
            <el-option
                v-for="item in secondSort"
                :key="item.categoryId"
                :label="item.content"
                :value="item.categoryId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支持发货的地区集合">
          <!--          <el-input v-model="batchDTO.acceptAddressList" autocomplete="off" ></el-input>-->
          <el-tag
              :key="tag"
              v-for="tag in dynamicTags"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)">
            {{ tag }}
          </el-tag>
          <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
          >
          </el-input>
          <el-button v-else class="button-new-tag" size="small" @click="showInput">+添加地区</el-button>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showBatchZip = false">取 消</el-button>
        <el-button type="primary" @click="handleBatchZip" v-loading.fullscreen.lock="fullscreenLoading">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="信息" :visible.sync="dialogFormVisible" width="60%">
      <el-form label-width="200px" size="small">
        <el-form-item label="商品图片">
          <el-upload
              class="avatar-uploader"
              :action="`${piPath}/image/upload`"
              :show-file-list="false"
              :on-success="handleImgSuccess"
          >
            <img v-if="form.originPath" :src="`${piPath}${form.originPath}`" class="avatar">

            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="商品编号">
          <el-input v-model="form.commodityId" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="商品原价">
          <el-input v-model="form.originPrice" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="商品现价">
          <el-input v-model="form.price" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input v-model="form.itemCommodityName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="发货人的电话">
          <el-input v-model="form.originPhone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="发货人的名字">
          <el-input v-model="form.originName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="发货地">
          <el-input v-model="form.originAddress" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="支持发货的地区集合">
          <el-input v-model="form.acceptAddressList" autocomplete="off"
                    placeholder='格式必须为["地区1","地区2"]'></el-input>
        </el-form-item>
        <el-form-item label="商品介绍">
          <el-input autocomplete="off"
                    type="textarea"
                    placeholder="请输入商品介绍"
                    v-model="form.details"
                    maxlength="100"
                    show-word-limit
          >
          </el-input>
        </el-form-item>
        <el-form-item label="库存">
          <el-input v-model="form.inventory" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="免运费的起始订购数量">
          <el-input v-model="form.freeShippingNum" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="商品一级分类">
          <el-select v-model="sort.categoryPid" filterable placeholder="请选择">
            <el-option
                v-for="item in firstSort"
                :key="item.categoryId"
                :label="item.content"
                :value="item.categoryId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品二级分类">
          <el-select v-model="form.categoryId" filterable placeholder="请选择">
            <el-option
                v-for="item in secondSort"
                :key="item.categoryId"
                :label="item.content"
                :value="item.categoryId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品的折损">
          <el-input v-model="form.degreeLoss" autocomplete="off"></el-input>
        </el-form-item>
        <!--        <el-form-item label="商品状态">
                  <el-input v-model="form.status" autocomplete="off"></el-input>
                </el-form-item>-->
        <el-form-item label="商品状态">
          <el-select v-model="form.status" placeholder="请选择">
            <el-option
                v-for="item in status"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "ItemCommodityInfo",
  data() {
    return {
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      vo: {
        itemCommodityName: '',
        commodityId: '',
        originName: '',
        categoryId: 0,
      },
      form: {},
      dialogFormVisible: false,
      multipleSelection: [],
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      firstSort: [],
      secondSort: [],
      sort: {},
      status: [{
        value: 0,
        label: '上架'
      }, {
        value: 1,
        label: '下架'
      }, {
        value: 2,
        label: '审核'
      }],
      showBatchZip: false,
      fileList: [],
      batchDTO: {
        mark: "",
        originPhone: '',
        originName: "",
        originAddress: '',
        status: 0,
        categoryId: null,
        acceptAddressList: "[]",
      },
      fullscreenLoading: false,
      categoryPid: '',
      //动态标签数据
      dynamicTags: [],
      inputVisible: false,
      inputValue: ''
    }
  },
  async mounted() {
    this.load()


  },
  watch: {
    async 'sort.categoryPid'(newVal) {
      await this.sortSon(newVal);
      console.log("2", this.secondSort)
      if (this.secondSort.length == 0) {
        this.form.categoryId = ''
      } else {
        this.form.categoryId = this.secondSort[0].categoryId
      }

    },
    async 'categoryPid'(newVal) {
      await this.sortSon(newVal);
      console.log("2", this.secondSort)
      if (this.secondSort.length == 0) {
        this.batchDTO.categoryId = ''
      } else {
        this.batchDTO.categoryId = this.secondSort[0].categoryId
      }

    }
  },
  methods: {

    //文件处理
    beforeUpload(file) {
      // Here you can add any validation logic for the file
      const isZip = file.type === 'application/zip' || file.name.endsWith('.zip');
      if (!isZip) {
        this.$message.error('只能上传zip文件');
      }
      return isZip;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1); // 限制只保留一个文件
    },
    handleImgSuccess(res) {
      console.log(res, "res")
      this.form.originPath = res.data.OriginPath
      this.form.avatarImag = res.data.imageId
    },
    //压缩包批量导入
    handleBatchZip() {
      if (this.fileList.length === 0) {
        this.$message.error('请先选择一个压缩包文件');
        return;
      }
      // 判断是否为空
      const requiredFields = {
        mark: '编号开头',
        originPhone: '发货人电话',
        originName: '发货人名字',
        originAddress: '发货地址'
      };

      for (const field in requiredFields) {
        if (this.batchDTO[field] === undefined || this.batchDTO[field] === null || this.batchDTO[field].toString().trim() === '') {
          this.$message.error(`请填写${requiredFields[field]}`);
          return;
        }
      }
      this.batchDTO.acceptAddressList = JSON.stringify(this.dynamicTags)
      console.log(this.batchDTO.acceptAddressList, "batchDTO")
      const formData = new FormData();
      formData.append('file', this.fileList[0].raw);
      formData.append('dto', new Blob([JSON.stringify(this.batchDTO)], {type: 'application/json'})); // 以 Blob 的形式传递 DTO
      this.fullscreenLoading = true;
      this.request.post('/itemCommodityInfo/zip/batch', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 60000 * 60 * 60 * 24
      }).then(res => {
        if (res.code == 200) {
          this.fullscreenLoading = false;
          this.$message.success('上传成功');
          this.showBatchZip = false;
          this.load()

        } else {
          this.fullscreenLoading = false;
          this.$message.error('上传失败');
        }
      }).catch(error => {
        this.fullscreenLoading = false;
        console.error(error);
        this.$message.error('上传过程中发生错误');
      });
    },
    //获取该商品分类
    async getSort(id) {
      await this.request.get(`/reclassify/${id}`
      ).then(res => {
        this.sort = res.data
        console.log("sort", this.sort)
      })
    },
    //二级分类
    async sortSon(id) {
      await this.request.get(`/reclassify/paging/${id}`, {
            params: {
              page: 1,
              limit: 10000,
            }
          }
      ).then(res => {
        this.secondSort = res.data.records
        console.log("second", this.secondSort)
      })
    },
    //商品一级分类
    async sortParent() {
      await this.request.get("/classify"
      ).then(res => {
        this.firstSort = res.data
        console.log("first", this.firstSort)
      })
    },
    async handleShowZip() {
      this.showBatchZip = true
      await this.sortParent()
      console.log("list", this.firstSort)
      this.categoryPid = this.firstSort[0].categoryId
      console.log(this.categoryPid, "pid")
    },
    //商品状态
    formatStatus(row) {
      switch (row.status) {
        case 0:
          return '上架';
        case 1:
          return '下架';
        case 2:
          return '审核中';
        default:
          return '未知状态';
      }
    },
    load() {
      this.request.get("/itemCommodityInfo/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          ...this.vo,
        }
      }).then(res => {
        this.tableData = res.data.records
        console.log("data", this.tableData)
        this.total = res.data.total
      })
    },
    save() {
// 验证必填字段
      const requiredFields = {
        itemCommodityName: '商品名称',
        originPrice: '商品原价',
        price: '商品现价',
        originPhone: '发货人的电话',
        originName: '发货人的名字',
        originAddress: '发货地',
        acceptAddressList: '支持发货的地区集合',
        details: '商品介绍',
        inventory: '库存',
        categoryId: '商品二级分类',
        avatarImag: '商品图片'
      };
      console.log("12345",!this.form['originPrice'])

      for (const field in requiredFields) {
        if (this.form[field] === undefined || this.form[field] === null || this.form[field].toString().trim() === '') {
          this.$message.error(`${requiredFields[field]}不能为空`);
          return;
        }
      }

      // 验证 acceptAddressList 格式
      try {
        const acceptAddressList = JSON.parse(this.form.acceptAddressList);
        if (!Array.isArray(acceptAddressList) || !acceptAddressList.every(item => typeof item === 'string')) {
          throw new Error();
        }
      } catch (error) {
        this.$message.error('支持发货的地区集合必须是 ["地区1", "地区2"] 格式的字符串');
        return;
      }

      this.request.put("/itemCommodityInfo/mod", {
        id: this.form.id,
        itemCommoditName: this.form.itemCommodityName,
        originPrice: this.form.originPrice,
        price: this.form.price,
        originPhone: this.form.originPhone,
        originName: this.form.originName,
        originAddress: this.form.originAddress,
        acceptAddressList: this.form.acceptAddressList,
        details: this.form.details,
        inventory: this.form.inventory,
        freeShippingNum: this.form.freeShippingNum,
        categoryId: this.form.categoryId,
        status: this.form.status,
        avatarImag: this.form.avatarImag
      }).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.form = {}
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    handleAdd() {
      this.dialogFormVisible = true
      this.form = {}
      this.$nextTick(() => {
        if (this.$refs.img) {
          this.$refs.img.clearFiles();
        }
        if (this.$refs.file) {
          this.$refs.file.clearFiles();
        }
      })
    },
    async handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      console.log(this.form, "form")
      //获取分类
      await this.getSort(this.form.categoryId)
      //获取一级分类
      await this.sortParent();
      //获取二级分类
      await this.sortSon(this.sort.categoryPid)
      this.dialogFormVisible = true
      this.$nextTick(() => {
        if (this.$refs.img) {
          this.$refs.img.clearFiles();
        }
        if (this.$refs.file) {
          this.$refs.file.clearFiles();
        }
      })
    },
    del(id) {
      this.request.delete("/itemCommodityInfo/del/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    delBatch() {
      let ids = this.multipleSelection.map(v => v.id)  // [{}, {}, {}] => [1,2,3]
      this.request.post("/itemCommodityInfo/del/batch", ids).then(res => {
        if (res.code === '200') {
          this.$message.success("批量删除成功")
          this.load()
        } else {
          this.$message.error("批量删除失败")
        }
      })
    },
    reset() {
      this.name = ""
      this.load()
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    handleFileUploadSuccess(res) {
      this.form.file = res
    },
    handleImgUploadSuccess(res) {
      this.form.img = res
    },
    download(url) {
      window.open(url)
    },
    exp() {
      window.open(this.path + "/itemCommodityInfo/excel/export")
    },
    handleExcelImportSuccess() {
      this.$message.success("导入成功")
      this.load()
    },
    handleZipImportSuccess() {
      this.load()
      this.batchDTO = {
        mark: "",
        originPhone: '',
        originName: "",
        originAddress: '',
        status: 0,
        categoryId: null,
        acceptAddressList: "",
      }
    },
    //动态标签
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    }
  }

}
</script>


<style>
.headerBg {
  background: #eee !important;
}

.avatar-uploader {
  text-align: center;
  padding-bottom: 10px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 138px;
  height: 138px;
  line-height: 138px;
  text-align: center;
}

.avatar {
  width: 138px;
  height: 138px;
  display: block;
}


.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
